import { graphql, Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import InPageNav from '../components/InPageNav'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEO'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
`

const MakeupsContainer = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 10px;
  }
`

const MakeupItem = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 0;
  font-size: 14px;
  line-height: 18px;
`

const MakeupByCategory = styled.div`
  /* max-width: 700px; // Refactor to use theme variable
  margin-left: auto;
  margin-right: auto; */

  .section-title {
    text-align: left;
    margin: 25px 0 15px;
  }
`

const StockKey = styled.div`
  /* max-width: 100%; */
  overflow-x: auto;
  margin-top: 15px;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 15px;
  padding-bottom: 5px;

  > div {
    display: flex;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
`

const StockType = styled.span`
  flex-shrink: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 13px;
  padding-left: 18px;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }
`
const outOfStockMax = 0
const lowStockMax = 50
const getStockColor = stockLevel => {
  let color = ''
  if (stockLevel <= outOfStockMax) {
    color = '#AAAAAA'
  } else if (stockLevel <= lowStockMax) {
    color = '#F8E71C'
  } else {
    color = '#7ED321'
  }
  return color
}
const StockIndicator = styled.span`
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${props => getStockColor(props.stockLevel)};
`

const Calendar = ({ data }) => {
  const [stateData, setStateData] = useState({ makeupItems: [] })

  useEffect(() => {
    function loadProducts() {
      setStateData({
        makeupItems: [
          {
            category: 'Highlights',
            items: [
              {
                name: 'Moonlit',
                id: '2369',
                quantity: '20',
              },
              {
                name: 'White Peach',
                id: '9827',
                quantity: '120',
              },
            ],
          },
          {
            category: 'Contour',
            items: [
              {
                name: 'Aspen',
                id: '0913',
                quantity: '298',
              },
            ],
          },
          {
            category: 'Lip + Cheek',
            items: [
              {
                name: 'Watermelon',
                id: '2987',
                quantity: '0',
              },
            ],
          },
        ],
      })
    }

    loadProducts()
  }, [])

  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Product Stock"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/products/" activeClassName="active-page-link">
            United States
          </Link>
          <Link to="/products-canada/" activeClassName="active-page-link">
            Canada
          </Link>
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <StockKey>
            <div>
              <StockType>
                <StockIndicator />
                In Stock
              </StockType>
              <StockType>
                <StockIndicator stockLevel={lowStockMax} />
                Running Low
              </StockType>
              <StockType>
                <StockIndicator stockLevel={outOfStockMax} />
                Out Of Stock
              </StockType>
            </div>
          </StockKey>
          <MakeupsContainer>
            {stateData.makeupItems.map(groupedMakeup => (
              <MakeupByCategory key={groupedMakeup.items[0].id}>
                <h1 className="section-title">{groupedMakeup.category}</h1>
                {groupedMakeup.items.map(item => {
                  return (
                    <MakeupItem key={item.id}>
                      <StockIndicator stockLevel={item.quantity} />
                      <span>{item.name}</span>
                    </MakeupItem>
                  )
                })}
              </MakeupByCategory>
            ))}
          </MakeupsContainer>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Calendar
